<template>
    <admin-table title="手术室设置" @search="handleSearch" :table="table" :loading="loading">
        <div slot="buttons">
            <el-button type="primary" size="small" @click="handleAdd">添加手术室</el-button>
        </div>
        <el-table-column type="index" width="50" align="center"/>
        <el-table-column align="center"
                         v-for="(v, idx) in table.columns"
                         :prop="v.field"
                         :label="v.title"
                         :width="v.width"
                         :key="idx">
        </el-table-column>
        <el-table-column align="center" label="操作" width="180">
            <template slot-scope="scope">
                <el-button @click="handleUpdate(scope.row)"
                           size="small"
                           type="warning"
                           icon="el-icon-edit">
                </el-button>
                <el-button @click="handleDel(scope.row)"
                           size="small"
                           type="danger"
                           icon="el-icon-delete"
                >
                </el-button>
            </template>
        </el-table-column>
        <el-dialog slot="dialog" title="手术室设置" :visible.sync="dialogVisible" width="50%" center>
            <el-form :model="form" :rules="rules" ref="form" label-width="120px">
                <el-form-item label="名称">
                    <el-input v-model="form.name" style="width:80%"></el-input>
                </el-form-item>
                <el-form-item label="位置">
                    <el-input v-model="form.location" style="width:80%"></el-input>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button @click="dialogVisible = false">取 消</el-button>
                <el-button type="primary" @click="addSubmit">确 定</el-button>
            </span>
        </el-dialog>
    </admin-table>
</template>


<script>
    export default {
        data() {
            return {
                loading: false,
                table: {
                    search: [
                        {
                            type: 'input',
                            key: 'keywords',
                            placeholder: '请输入关键词'
                        }
                    ],
                    columns: [
                        {title: '名称', field: 'name', width: ''},
                        {title: '位置', field: 'location', width: ''},
                    ],
                    data: [],
                    total: 0
                },
                dialogVisible: false,
                form: {
                    name: '',
                    location: '',
                },
                rules: {
                    doctor: [
                        {required: true, message: '请输入', trigger: 'blur'},
                    ]
                }
            }
        },
        methods: {
            handleAdd() {
                this.form = {name: '', location: ''}
                this.dialogVisible = true
            },
            handleUpdate(row) {
                this.dialogVisible = true
                this.form = row
            },
            async handleDel(row){
                const resp = await this.$http.delete(`/surgery/area/${row.ID}`)
                console.log(resp)
                this.$message.success("删除成功")
                this.getData()
            },
            async addSubmit() {
                let resp = null
                if (this.form.id) {
                    resp = await this.$http.put(`/surgery/area/${this.form.id}`, this.form)
                } else {
                    resp = await this.$http.post('/surgery/area/', this.form)
                }
                if (resp.data.code == 200) {
                    this.$message.success("保存成功")
                    this.dialogVisible = false
                } else {
                    this.$message.error(resp.data.msg)
                }
                this.getData()
            },
            async getData(params) {
                const resp = await this.$http.get('/surgery/area/', {params: params})
                this.table.data = resp.data.data
            },
            handleSearch(e) {
                this.getData(e)
            },
        },
        mounted() {
            this.getData()
        }
    }
</script>

<style scoped>
    .search {
        padding-bottom: 40px;
        text-align: center;
    }

</style>
